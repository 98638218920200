html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
input,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: 100% inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  scroll-behavior: smooth; 
  padding: 0;
  margin: auto;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: unset;
  box-sizing: border-box;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.indicators {
  padding: 0;
}

img,
video {
  display: block;
}

button,
a {
  border: none;
  cursor: pointer;
}

li {
  display: unset;
}

/* mobile */
.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 48rem) {
  .tablet-row {
    flex-direction: row;
  }
  .tablet-row-reverse {
    flex-direction: row-reverse;
  }
  .tablet-column {
    flex-direction: column;
  }

  .tablet-column-reverse {
    flex-direction: column-reverse;
  }
}

/* laptop */

@media (min-width: 75rem) {
  .laptop-row {
    flex-direction: row;
  }
  .laptop-row-reverse {
    flex-direction: row-reverse;
  }
  .laptop-column {
    flex-direction: column;
  }

  .laptop-column-reverse {
    flex-direction: column-reverse;
  }

  .laptop-left-small {
    padding-left: 2.5rem;
  }
}

.PhoneInputInput:focus-visible {
  outline: none;
}

.PhoneInputInput {
  border: none;
}
