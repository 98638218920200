html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
input,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: 100% inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  scroll-behavior: smooth; 
  padding: 0;
  margin: auto;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: unset;
  box-sizing: border-box;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.indicators {
  padding: 0;
}

img,
video {
  display: block;
}

button,
a {
  border: none;
  cursor: pointer;
}

li {
  display: unset;
}

/* mobile */
.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

@media (min-width: 48rem) {
  .tablet-row {
    flex-direction: row;
  }
  .tablet-row-reverse {
    flex-direction: row-reverse;
  }
  .tablet-column {
    flex-direction: column;
  }

  .tablet-column-reverse {
    flex-direction: column-reverse;
  }
}

/* laptop */

@media (min-width: 75rem) {
  .laptop-row {
    flex-direction: row;
  }
  .laptop-row-reverse {
    flex-direction: row-reverse;
  }
  .laptop-column {
    flex-direction: column;
  }

  .laptop-column-reverse {
    flex-direction: column-reverse;
  }

  .laptop-left-small {
    padding-left: 2.5rem;
  }
}

.PhoneInputInput:focus-visible {
  outline: none;
}

.PhoneInputInput {
  border: none;
}

@charset "UTF-8";
.szh-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  width: max-content;
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.szh-menu:focus {
  outline: none;
}
.szh-menu__arrow {
  box-sizing: border-box;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #fff;
  border: 1px solid transparent;
  border-left-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
}
.szh-menu__arrow--dir-left {
  right: -0.375rem;
  transform: translateY(-50%) rotate(135deg);
}
.szh-menu__arrow--dir-right {
  left: -0.375rem;
  transform: translateY(-50%) rotate(-45deg);
}
.szh-menu__arrow--dir-top {
  bottom: -0.375rem;
  transform: translateX(-50%) rotate(-135deg);
}
.szh-menu__arrow--dir-bottom {
  top: -0.375rem;
  transform: translateX(-50%) rotate(45deg);
}
.szh-menu__item {
  cursor: pointer;
}
.szh-menu__item:focus {
  outline: none;
}
.szh-menu__item--hover {
  background-color: #ebebeb;
}
.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}
.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}
.szh-menu__group {
  box-sizing: border-box;
}
.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.szh-menu__divider {
  height: 1px;
  margin: 0.5rem 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: #212529;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.133), 0 0.6px 2px rgba(0, 0, 0, 0.1);
  min-width: 10rem;
  padding: 0.5rem 0;
}
.szh-menu__item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.375rem 1.5rem;
}
.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}
.szh-menu__item--type-radio::before {
  content: "○";
  position: absolute;
  left: 0.8rem;
  top: 0.55rem;
  font-size: 0.8rem;
}
.szh-menu__item--type-radio.szh-menu__item--checked::before {
  content: "●";
}
.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}
.szh-menu__item--type-checkbox::before {
  position: absolute;
  left: 0.8rem;
}
.szh-menu__item--type-checkbox.szh-menu__item--checked::before {
  content: "✔";
}
.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}
.szh-menu__submenu > .szh-menu__item::after {
  content: "❯";
  position: absolute;
  right: 1rem;
}
.szh-menu__header {
  color: #888;
  font-size: 0.8rem;
  padding: 0.2rem 1.5rem;
  text-transform: uppercase;
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    transform: translateX(0.75rem);
  }
}
@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    transform: translateX(-0.75rem);
  }
}
@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    transform: translateY(0.75rem);
  }
}
@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    transform: translateY(-0.75rem);
  }
}
.szh-menu--state-opening.szh-menu--dir-left {
  animation: szh-menu-show-slide-left 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  animation: szh-menu-hide-slide-left 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-right {
  animation: szh-menu-show-slide-right 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  animation: szh-menu-hide-slide-right 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-top {
  animation: szh-menu-show-slide-top 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  animation: szh-menu-hide-slide-top 0.15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  animation: szh-menu-show-slide-bottom 0.15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  animation: szh-menu-hide-slide-bottom 0.15s ease-in forwards;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Montserrat_4bc053';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/3f69592b2fe603c7-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Montserrat_4bc053';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6325a8417175c41d-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Montserrat_4bc053';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/021bc4481ed92ece-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Montserrat_4bc053';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/99b7f73d5af7c3e2-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Montserrat_4bc053';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/4f05ba3a6752a328-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Montserrat_Fallback_4bc053';src: local("Arial");ascent-override: 84.95%;descent-override: 22.03%;line-gap-override: 0.00%;size-adjust: 113.95%
}.__className_4bc053 {font-family: '__Montserrat_4bc053', '__Montserrat_Fallback_4bc053';font-style: normal
}

